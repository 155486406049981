import { useContext, useMemo, createContext, useState } from 'react';

import { noop } from 'ms-utils/misc';

import type { GamificationModalState } from './types';

type OpenModalOptions<T extends GamificationModalState['state']> = Extract<
  GamificationModalState,
  { state: T }
> extends { options?: infer O }
  ? O
  : undefined;

type GamificationContextType = {
  modalState: GamificationModalState;
  openModal: <T extends GamificationModalState['state']>(
    state: T,
    options?: OpenModalOptions<T>,
  ) => void;
  closeModal: VoidFunction;
};

const initialModalState: GamificationModalState = {
  isOpen: false,
  state: 'dailyChallenges',
};

const GamificationContext = createContext<GamificationContextType>({
  modalState: initialModalState,
  openModal: noop,
  closeModal: noop,
});

export function GamificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [modalState, setModalState] =
    useState<GamificationModalState>(initialModalState);

  const value = useMemo(() => {
    function openModal<T extends GamificationModalState['state']>(
      state: T,
      options?: OpenModalOptions<T>,
    ) {
      if (options != null) {
        setModalState({ isOpen: true, state, options });
      } else {
        setModalState({ isOpen: true, state });
      }
    }

    function closeModal() {
      setModalState({ ...modalState, isOpen: false });
    }

    return { modalState, openModal, closeModal };
  }, [modalState]);

  return (
    <GamificationContext.Provider value={value}>
      {children}
    </GamificationContext.Provider>
  );
}

export function useGamificationContext() {
  return useContext(GamificationContext);
}
