import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const ArrowDownRight = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={10}
    viewBoxHeight={10}
  >
    <path d="M7.966 8.082a.624.624 0 0 0 1.248 0v-6.18a.624.624 0 1 0-1.248 0v6.18Zm1.066-.441-6.18-6.18c-.59-.59-1.472.294-.884.882l6.18 6.18c.59.59 1.472-.294.884-.882Zm-.442-.183H2.41c-.833 0-.833 1.248 0 1.248h6.18c.832 0 .832-1.248 0-1.248Z" />
  </Icon>
);

ArrowDownRight.displayName = 'ArrowDownRight';

export default ArrowDownRight;
