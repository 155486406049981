import { css, cx } from '@emotion/css';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';

const styles = {
  default: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 2 * BASE_UNIT,
    color: colors.cinnabar,
    fontWeight: fontWeight.semibold,
    fontFamily: fontFamily.body,
  }),
  isCompact: css({
    padding: BASE_UNIT,
    fontSize: 14,
  }),
};

type Props = {
  retry: (() => void) | null | undefined;
  message?: string;
  isCompact?: boolean;
};

export default function Retry({ retry, message, isCompact }: Props) {
  return (
    <div className={cx(styles.default, isCompact && styles.isCompact)}>
      <>{message ?? 'An error occurred'}</>
      <Button
        onClick={() => {
          retry?.();
        }}
        padding={isCompact ? 4 : undefined}
        height={isCompact ? 16 : undefined}
      >
        Retry
      </Button>
    </div>
  );
}
