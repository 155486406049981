import { css } from '@emotion/css';
import type { ReactNode, ReactElement } from 'react';
import { cloneElement } from 'react';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import Latex from 'ms-components/Latex';
import ReloadableImage from 'ms-components/ReloadableImage/ReloadableImage';
import BoxPlotReadOnly from 'ms-components/math/BoxPlotReadOnly';
import Geogebra from 'ms-components/math/Geogebra';
import GraphPlotReadOnly from 'ms-components/math/GraphPlotReadOnly';
import HintContext from 'ms-components/math/HintContext';
import HistogramReadOnly from 'ms-components/math/HistogramReadOnly';
import { ChapterLink } from 'ms-components/math/MathContent/ChapterLink';
import NumberBuilderReadOnly from 'ms-components/math/NumberBuilderReadOnly';
import NumberLineIntervalsReadOnly from 'ms-components/math/NumberLineIntervalsReadOnly';
import ProbabilityTreeReadOnly from 'ms-components/math/ProbabilityTreeReadOnly';
import ProblemPreview from 'ms-components/math/ProblemPreview';
import { TranslatedMultipleChoiceOption } from 'ms-components/math/TranslatedMultipleChoiceOption/TranslatedMultipleChoiceOption';
import VideoLauncher from 'ms-components/math/VideoLauncher';
import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import VideoEmbed from 'ms-ui-primitives/VideoEmbed';

// TODO: REMOVE THESE STYLES!
// This is a stopgap. It should help us prove the concept, but will be loaded
// on most pages, and risks leaking into other components.
import cssStyles from './index.css';
import renderNode, { type NodeRendererMap } from './renderNode';

type SoupHtml = string;

/**
 * <MathContent>
 *
 * <MathContent> is for rendering rich documents containing embedded math
 * artifacts (such as expressions, graphs, number lines, etc.)
 *
 * It does this by consuming a document serialization format called SoupHtml
 * which is produced by the server. This component consumes this curious brand
 * of HTML, deserializes it into a DOM tree, then recurses over this tree
 * during the render cycle and does a one-to-one conversion of DOM nodes into
 * the React components that each one is an encoding for.
 *
 * This is a VERY WEIRD component (but also a very important one) so a
 * paper doc has been written to give more insight into the entire context
 * surrounding this component. You should read it.
 *
 * https://paper.dropbox.com/doc/MathContent-explainer-4YiFs9a1p1fBe8nWQ6wUX
 *
 */

/**
 * THIS IS THE CORE OF `<MathContent />`!
 *
 * Recursively transform a DOM tree into a React Element Tree, using the set
 * of nodeRenderers provided.
 *
 * @param {*} node A DOM node that is the root of a DOM tree.
 * @param {*} key A unique value
 * @param {*} nodeRenderers A map of MathContentType to node renderer
 */
const renderTree = (
  node: Node,
  key: number,
  nodeRenderers: NodeRendererMap,
): ReactNode => {
  const reactNode = renderNode(node, key, nodeRenderers);

  if (
    reactNode == null ||
    typeof reactNode === 'string' ||
    // We must ensure we do not attempt to render children when there aren't
    // any. Otherwise, React will complain for components such as <input />.
    node.childNodes.length === 0
  ) {
    return reactNode;
  }

  return cloneElement(
    reactNode as ReactElement,
    { key }, // existing keys do not survive cloning
    [...node.childNodes].map((child, i) => renderTree(child, i, nodeRenderers)),
  );
};

// NOTE good perf gain available here by wrapping a react-loadable around
// each of the component types.
const defaultNodeRenderers: NodeRendererMap = {
  PROBABILITY_TREE_STATIC: props => <ProbabilityTreeReadOnly {...props} />,
  HISTOGRAM_STATIC: props => <HistogramReadOnly {...props} />,
  NUMBER_LINE_STATIC: props => <NumberLineIntervalsReadOnly {...props} />,
  NUMBER_BUILDER_STATIC: props => <NumberBuilderReadOnly {...props} />,
  BOX_PLOT_STATIC: props => <BoxPlotReadOnly {...props} />,
  LATEX_INPUT_STATIC_OR_DYNAMIC: props => <Latex {...props} />,
  GRAPHPLOT_STATIC: props => <GraphPlotReadOnly {...props} />,
  LATEX_EXPRESSION: props => <Latex {...props} />,
  GEOGEBRA_EMBED: props => <Geogebra {...props} />,
  VIDEO_EMBED: props => <VideoEmbed {...props} />,
  PROBLEM_PREVIEW: props => <ProblemPreview {...props} />,
  VIDEO_LAUNCHER: props => <VideoLauncher {...props} />,
  CHAPTER_LINK: props => <ChapterLink {...props} />,
  HINT_CONTEXT: props => <HintContext {...props} />,
  IMAGE: props => <ReloadableImage {...props} />,
  TRANSLATED_MULTIPLE_CHOICE_OPTION: props => {
    return <TranslatedMultipleChoiceOption {...props} />;
  },
};

/**
 * Deserializes MathContent SoupHTML into a DOM tree.
 */
const deserializeContent = ({
  className,
  content,
  inline,
}: {
  className: string;
  content: SoupHtml;
  inline: boolean | undefined;
}): HTMLElement => {
  const root = document.createElement(inline ? 'span' : 'div');
  root.className = className;
  root.innerHTML = content;
  return root;
};

type Props = {
  content: SoupHtml;
  inline?: boolean;
  nodeRenderers?: Partial<NodeRendererMap> | undefined;
  className?: string;
};

function MathContent(props: Props): JSX.Element {
  const { content, inline, className } = props;

  const rootClassName = className
    ? `${cssStyles.root} ${className}`
    : // Using ! (not unwrap) as the testing env is busted for CSS modules
      cssStyles.root!;

  const root = deserializeContent({
    className: rootClassName,
    content,
    inline,
  });

  // User provided renderers take precedence over the defaults.
  const nodeRenderers = {
    ...defaultNodeRenderers,
    ...props.nodeRenderers,
  };

  return renderTree(root, 0, nodeRenderers) as JSX.Element;
}

export default function MathContentWithErrorBoundary(
  props: Props,
): JSX.Element {
  return (
    <ErrorBoundary
      name="MathContent"
      fallback={<div className={styles.error}>Error loading content</div>}
    >
      <MathContent {...props} />
    </ErrorBoundary>
  );
}

const styles = {
  error: css({
    color: colors.brickRed,
    fontFamily: fontFamily.ui,
    fontWeight: fontWeight.semibold,
  }),
};
