import { useContext, useCallback } from 'react';

import { SnowplowContext, type SnowplowContextType } from './SnowplowProvider';
import type { SupportedStructEvent } from './types';

type WithTrackStructEvent =
  | SupportedStructEvent
  | ((...args: any) => SupportedStructEvent);

export type WithTrackStructEventHook = (
  callback: AnyFunction,
  arg1: WithTrackStructEvent,
) => AnyFunction;

type SnowplowHook = {
  withTrackStructEvent: WithTrackStructEventHook;
} & SnowplowContextType;

// TODO we actually want to make everything generic, such that the
// function you get back matches the signature of the function
// you pass to withTrackStructEvent()
type AnyFunction = (...args: any[]) => any;

export function useSnowplow(): SnowplowHook {
  const context: SnowplowContextType = useContext(SnowplowContext);
  const withTrackStructEvent = useCallback(
    (cb: AnyFunction, structuredEvent: WithTrackStructEvent) =>
      (...args: any[]) => {
        context.trackStructEvent(
          typeof structuredEvent === 'function'
            ? structuredEvent(...args)
            : structuredEvent,
        );
        return cb(...args);
      },
    [context], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return {
    ...context,
    withTrackStructEvent,
  };
}
