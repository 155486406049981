import type { Dimensions } from 'ms-utils/math/cartesian';

import Element from '../Element';
import type { NumberLineSegmentData } from '../index';

type Props = {
  dimensions: Dimensions;
  dragging: boolean;
  dx: number;
  onDragEnd: (id: number, data: NumberLineSegmentData) => void;
  onDragStart: (event: React.PointerEvent) => void;
  readOnly: boolean;
  value: ReadonlyArray<NumberLineSegmentData>;
};

export default function Elements(props: Props) {
  return (
    <g>
      {props.value.map((element, i) => (
        <Element
          dimensions={props.dimensions}
          dragging={props.dragging}
          dx={props.dx}
          element={element}
          key={i}
          onDragEnd={props.onDragEnd}
          onDragStart={props.onDragStart}
          reactKey={i}
          readOnly={props.readOnly}
        />
      ))}
    </g>
  );
}
