import type { Dimensions } from 'ms-utils/math/cartesian';

import Segment from '../Segment';
import type { NumberLineSegmentData } from '../index';

type Props = {
  dimensions: Dimensions;
  dragging: boolean;
  dx: number;
  element: NumberLineSegmentData;
  onDragEnd: (id: number, data: NumberLineSegmentData) => void;
  onDragStart: (event: React.PointerEvent) => void;
  reactKey: number;
  readOnly: boolean;
};

export default function Element({
  dimensions,
  dragging,
  dx,
  element,
  onDragEnd,
  onDragStart,
  reactKey,
  readOnly,
}: Props) {
  return (
    <Segment
      data={element}
      dimensions={dimensions}
      dx={dx}
      onDragEnd={data => onDragEnd(reactKey, data)}
      onDragStart={dragging || readOnly ? undefined : onDragStart}
      readOnly={readOnly}
    />
  );
}
