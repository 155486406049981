import styled from '@emotion/styled';
import { useCallback } from 'react';
import { graphql, useMutation, useFragment } from 'react-relay';

import { useSnackbar } from 'ms-components/Snackbar';
import CrossBoldIcon from 'ms-components/icons/CrossBold';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import ConfirmUpdateLearningFocusModal from 'ms-pages/Lantern/views/Student/StudentSkillsMap/ConfirmUpdateLearningFocusModal/ConfirmUpdateLearningFocusModal';
import { borderRadiusUILarge } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { VSpacer, HSpacer, HStack, VStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { SuggestedLearningFocusNotificationResetMutation } from './__generated__/SuggestedLearningFocusNotificationResetMutation.graphql';
import type { SuggestedLearningFocusNotification_learningFocus$key } from './__generated__/SuggestedLearningFocusNotification_learningFocus.graphql';
import type { SuggestedLearningFocusNotification_suggestedLearningFocus$key } from './__generated__/SuggestedLearningFocusNotification_suggestedLearningFocus.graphql';

const LEARNING_FOCUS_FRAGMENT = graphql`
  fragment SuggestedLearningFocusNotification_learningFocus on Substrand {
    title
  }
`;
const SUGGESTED_LEARNING_FOCUS_FRAGMENT = graphql`
  fragment SuggestedLearningFocusNotification_suggestedLearningFocus on Substrand {
    id
    title
  }
`;
type Props = {
  learningFocus: SuggestedLearningFocusNotification_learningFocus$key;
  suggestedLearningFocus: SuggestedLearningFocusNotification_suggestedLearningFocus$key;
  onDismiss: () => void;
};
export function SuggestedLearningFocusNotification({
  learningFocus: learningFocusRef,
  suggestedLearningFocus: suggestedLearningFocusRef,
  onDismiss,
}: Props) {
  const { enqueueMessage } = useSnackbar();
  const learningFocus = useFragment(LEARNING_FOCUS_FRAGMENT, learningFocusRef);
  const suggestedLearningFocus = useFragment(
    SUGGESTED_LEARNING_FOCUS_FRAGMENT,
    suggestedLearningFocusRef,
  );
  const confirmUpdateLearningFocusModal = useBoolean();
  const [resetSuggestedLearningFocus] =
    useMutation<SuggestedLearningFocusNotificationResetMutation>(graphql`
      mutation SuggestedLearningFocusNotificationResetMutation {
        resetSuggestedLearningFocus {
          errors {
            key
            message
          }
          student {
            suggestedLearningFocus {
              id
            }
          }
        }
      }
    `);
  const closeAndReset = useCallback(() => {
    resetSuggestedLearningFocus({
      variables: {},
      onCompleted: (
        { resetSuggestedLearningFocus: { errors: responseErrors } },
        errors,
      ) => {
        const allErrors = [...responseErrors, ...(errors ?? [])];
        if (allErrors.length > 0) {
          allErrors.forEach(error => {
            enqueueMessage({ text: error.message });
          });
        }
      },
      onError: error => {
        enqueueMessage({ text: error.message });
      },
    });
    onDismiss();
  }, [enqueueMessage, onDismiss, resetSuggestedLearningFocus]);
  if (suggestedLearningFocus == null) return null;
  return (
    <>
      <SuggestedLearningFocusNotificationPresentational
        substrandTitle={suggestedLearningFocus.title}
        onConfirm={confirmUpdateLearningFocusModal.setTrue}
        closeAndReset={closeAndReset}
      />
      <ConfirmUpdateLearningFocusModal
        isOpen={confirmUpdateLearningFocusModal.value}
        onClose={confirmUpdateLearningFocusModal.setFalse}
        currentLearningFocusTitle={learningFocus?.title}
        nextLearningFocusTitle={suggestedLearningFocus.title}
        nextSubstrandId={suggestedLearningFocus.id}
      />
    </>
  );
}
const Banner = styled.div<{}>({
  padding: `${2 * BASE_UNIT}px ${4 * BASE_UNIT}px`,
  backgroundColor: colors.eggplant20,
  borderRadius: borderRadiusUILarge,
});
const CloseButtonWrapper = styled.div<{}>({
  // eyeball the values to offset the tap area
  marginRight: -12,
  marginTop: -4,
});
const TertiaryButtonWrapper = styled.div<{}>({
  lineHeight: '24px', // same as `BodyM`
});
type PresentationalProps = {
  substrandTitle: string;
  onConfirm: () => void;
  closeAndReset: () => void;
};
export function SuggestedLearningFocusNotificationPresentational({
  substrandTitle,
  onConfirm,
  closeAndReset,
}: PresentationalProps) {
  return (
    <Banner>
      <HStack>
        <VStack>
          <BodyM>
            Change skills focus to <Bold>{substrandTitle}</Bold> based from the
            task you last worked on?
          </BodyM>
          <VSpacer height={BASE_UNIT / 2} />
          <TertiaryButtonWrapper>
            <Button onClick={onConfirm} size="regular" type="tertiary" isInline>
              Yes, change
            </Button>
          </TertiaryButtonWrapper>
        </VStack>
        <HSpacer width={16} grow />
        <CloseButtonWrapper>
          <Button onClick={closeAndReset} label="Close" color="grey" isCircle>
            <CrossBoldIcon size={14} />
          </Button>
        </CloseButtonWrapper>
      </HStack>
    </Banner>
  );
}
