import styled from '@emotion/styled';
import React, { type ReactNode, type SyntheticEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { PAGE_PADDING } from 'ms-components/Layout/styles';
import { fontWeight, fontFamily, fontSize, breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import {
  useAccessibilityMode,
  accessibilityModeStyle,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
} from 'ms-utils/accessibility';
import { tappable } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';
import { assertUnreachable } from 'ms-utils/typescript-utils';

const ERROR_EITHER_ONCLICK_HREF =
  'NavElement must be either passed an onClick or a href prop';

export const navElementStyle = {
  default: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    color: colors.shuttleGray,
    padding: PAGE_PADDING / 4,
    textDecoration: 'none',
    border: `${FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH}px solid transparent`,
    ...tappable,
    fontWeight: fontWeight.semibold,
    ':active': {
      outline: 'none',
      backgroundColor: colors.ironLight,
    },
    [`@media (min-width: ${breakPoints.large}px)`]: {
      padding: PAGE_PADDING / 2,
    },
  },
  accessibilityMode: {
    ...accessibilityModeStyle,

    ':focus': {
      ...accessibilityModeStyle[':focus'],
      background: 'transparent',
    },
  },
} as const;

const NavElementDiv = styled.div<{ accessibilityMode: boolean }>(
  ({ accessibilityMode }) => ({
    ...navElementStyle.default,
    ...(accessibilityMode ? navElementStyle.accessibilityMode : {}),
  }),
);

const NavElementAnchor = styled.a<{ accessibilityMode: boolean }>(
  ({ accessibilityMode }) => ({
    ...navElementStyle.default,
    ...(accessibilityMode ? navElementStyle.accessibilityMode : {}),
    ':focus': {
      outline: 'none',
      backgroundColor: colors.ironLight,
    },
  }),
);

const NavElementLink = styled(Link)<{ accessibilityMode: boolean }>(
  ({ accessibilityMode }) => ({
    ...navElementStyle.default,
    ...(accessibilityMode ? navElementStyle.accessibilityMode : {}),
  }),
);

const PIP_SIZE = 6;
const SelectedPip = styled.div<{ selected: boolean | undefined }>(
  ({ selected }) => ({
    position: 'absolute',
    top: -FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
    left: 0,
    width: '100%',
    height: PIP_SIZE,
    backgroundColor: colors.cloudBurst,
    display: selected ? 'block' : 'none',
    borderBottomLeftRadius: PIP_SIZE,
    borderBottomRightRadius: PIP_SIZE,
  }),
);

const Highlight = styled.div<{ selected: boolean | undefined }>(
  ({ selected }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: 8,
    ...(selected ? { backgroundColor: colors.eggplant10 } : {}),
  }),
);

const NavLabel = styled.div({
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  display: 'flex',
  alignItems: 'center',
  zIndex: 5,

  [`@media (min-width: ${breakPoints.small}px)`]: {
    padding: BASE_UNIT,
  },
});

type Props = {
  children: ReactNode;
  selected?: boolean;
  active?: boolean;
  label?: string;
  // Mobile views of the student navbar has an alternate active style
  activeStyle?: 'overline' | 'highlight';
  // TODO: Make this valid keys of colors
  activeColor?: string;
  isLink?: boolean;
} & ({ onClick: (event: SyntheticEvent<any>) => void } | { href: string });

export default React.forwardRef<HTMLElement, Props>(
  function MenuElement(props, ref) {
    const [hasEnabledAccessibilityMode] = useAccessibilityMode();
    const history = useHistory();

    const navigateTo = React.useCallback<(link: string) => () => void>(
      link => () => {
        history.push(link);
      },
      [history],
    );
    const commonProps = React.useMemo(
      () => ({
        selected: props.selected ?? false,
        active: props.active ?? false,
        accessibilityMode: hasEnabledAccessibilityMode,
      }),
      [hasEnabledAccessibilityMode, props.active, props.selected],
    );

    const color = React.useMemo(() => {
      if (commonProps.selected || commonProps.active) {
        return props.activeColor ?? colors.cloudBurst;
      }
      return undefined;
    }, [commonProps.active, commonProps.selected, props.activeColor]);

    const children = React.useMemo(
      () => (
        <>
          {props.activeStyle === 'overline' ? (
            <SelectedPip selected={props.selected} />
          ) : (
            <Highlight selected={props.selected} />
          )}
          <NavLabel style={{ color }}>{props.children}</NavLabel>
        </>
      ),
      [color, props.activeStyle, props.children, props.selected],
    );

    if ('href' in props) {
      const onKeyDown = keyDownMap({
        ENTER: [navigateTo(props.href), { preventDefault: true }],
        SPACE: [navigateTo(props.href), { preventDefault: true }],
      });
      return props.isLink ? (
        <NavElementLink
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...commonProps}
          onKeyDown={onKeyDown}
          to={props.href}
          aria-label={props.label}
        >
          {children}
        </NavElementLink>
      ) : (
        <NavElementAnchor
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...commonProps}
          onKeyDown={onKeyDown}
          href={props.href}
          aria-label={props.label}
        >
          {children}
        </NavElementAnchor>
      );
    } else if ('onClick' in props) {
      return (
        <NavElementDiv
          tabIndex={0}
          ref={ref as React.Ref<HTMLDivElement>}
          {...commonProps}
          onKeyDown={keyDownMap({
            ENTER: [props.onClick, { preventDefault: true }],
            SPACE: [props.onClick, { preventDefault: true }],
          })}
          onClick={props.onClick}
        >
          {children}
        </NavElementDiv>
      );
    } else {
      assertUnreachable(props, ERROR_EITHER_ONCLICK_HREF);
    }
  },
);
