import { css, cx } from '@emotion/css';
import { Fit, Layout } from '@rive-app/react-canvas';
import { useEffect } from 'react';
import { useMutation, useFragment, graphql } from 'react-relay';

import { formatOrdinals } from 'ms-experiments/gamification/utils';
import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';
import {
  BodyS,
  HeadingS,
  HeadingXSGilroy,
} from 'ms-pages/Lantern/primitives/Typography';
import Stack from 'ms-ui-primitives/Stack';
import type { FixGeneratedKey } from 'ms-utils/relay/relay-compiler-language-typescript-fixes';
import { useTypedRive } from 'ms-utils/rive/useTypedRive';

import type { LeaderboardNotificationScreen_leaderboardEntry } from './__generated__/LeaderboardNotificationScreen_leaderboardEntry.graphql';
import type { LeaderboardNotificationScreen_workout$key } from './__generated__/LeaderboardNotificationScreen_workout.graphql';
import miloCelebratingRiv from './milo_celebrating.riv';
import {
  screenBodyCss,
  screenFooterCss,
  screenLeadTextCss,
  screenTitleCss,
} from '../emotionStyles';

export function LeaderboardNotificationScreen({
  actions,
  workoutKey,
}: {
  actions: React.ReactNode;
  workoutKey: FixGeneratedKey<LeaderboardNotificationScreen_workout$key>;
}) {
  const { trackStructEvent } = useSnowplow();
  const workout = useFragment(
    graphql`
      fragment LeaderboardNotificationScreen_workout on WorkoutInterface
      @argumentDefinitions(
        classId: { type: "ID" }
        hasLeaderboardClass: { type: "Boolean!" }
      ) {
        user {
          profile {
            __typename
            ... on Student {
              id
              leaderboard @include(if: $hasLeaderboardClass) {
                currentWeek(classId: $classId) {
                  ...LeaderboardNotificationScreen_leaderboardEntry
                    @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    workoutKey,
  );

  const { profile } = workout.user;
  const student = profile.__typename === 'Student' ? profile : null;
  const leaderboardEntries = student?.leaderboard?.currentWeek ?? [];
  const currUserLeaderboardEntry = leaderboardEntries.find(
    entry => entry.studentId === student?.id,
  );
  const currUserRank = currUserLeaderboardEntry?.rank ?? Infinity;
  const studentIndex = leaderboardEntries.findIndex(
    entry => entry.studentId === student?.id,
  );
  const leaderboardWindowStart = Math.max(0, studentIndex - 1);
  const visibleLeaderboardEntries = leaderboardEntries.slice(
    leaderboardWindowStart,
    leaderboardWindowStart + 3,
  );

  useEffect(() => {
    trackStructEvent({
      category: 'gamification',
      action: 'hasSeenGoneUpInLeaderboardScreen',
    });
  }, [trackStructEvent]);

  return (
    <>
      <div
        className={screenBodyCss}
        style={{ position: 'relative', width: '100%' }}
      >
        <h2 className={screenTitleCss}>Moving on up!</h2>
        <p className={screenLeadTextCss}>
          Great job! You’ve moved up to {formatOrdinals(currUserRank)} place on
          the leaderboard.
        </p>
        <div>
          {visibleLeaderboardEntries.map(entry => (
            <LeaderboardRow
              key={entry.studentId}
              entry={entry}
              isCurrentUser={entry.studentId === student?.id}
            />
          ))}
        </div>
        <div className={styles.miloCelebratingWrapper}>
          <MiloCelebratingAnimation />
        </div>
      </div>
      <footer className={screenFooterCss}>{actions}</footer>
    </>
  );
}

graphql`
  fragment LeaderboardNotificationScreen_leaderboardEntry on CurrentWeekLeaderboardUser
  @relay(mask: false) {
    studentId
    rank
    rankDelta
    name
    points
    avatarUrl
  }
`;

function LeaderboardRow({
  entry,
  isCurrentUser,
}: {
  entry: LeaderboardNotificationScreen_leaderboardEntry;
  isCurrentUser: boolean;
}) {
  const color = isCurrentUser ? 'elfGreen' : 'grey';
  return (
    <div
      className={cx(
        styles.leaderboardRow,
        isCurrentUser && styles.leaderboardRowCurrentUser,
      )}
    >
      <Stack.H center width={36}>
        <HeadingS color={color}>{entry.rank}</HeadingS>
      </Stack.H>

      <img src={entry.avatarUrl} alt="" />

      <HeadingXSGilroy color={color}>{entry.name}</HeadingXSGilroy>

      <Stack.H center style={{ marginLeft: 'auto' }}>
        <BodyS bold color={color}>
          {entry.points} points
        </BodyS>
      </Stack.H>
    </div>
  );
}

function MiloCelebratingAnimation() {
  const { RiveComponent } = useTypedRive({
    src: miloCelebratingRiv,
    stateMachine: 'State Machine 1',
    artboard: 'Artboard',
    autoplay: true,
    layout: new Layout({ fit: Fit.Contain }),
  });

  return <RiveComponent />;
}

export function useRecordLeaderboardRank({
  shouldRecord = true,
}: {
  shouldRecord?: boolean;
} = {}) {
  const [commitUpdateSnapshotMutation] = useMutation(graphql`
    mutation LeaderboardNotificationScreen_UpdateLeaderboardSnapshotMutation {
      updateLeaderboardRankSnapshot {
        errors {
          key
          message
        }
        didUpdate
      }
    }
  `);

  useEffect(() => {
    let dispose: VoidFunction = () => {};
    if (shouldRecord) {
      dispose = commitUpdateSnapshotMutation({
        variables: {},
        // Deliberately ignoring errors here for now. We can add logging later if we need it.
      }).dispose;
    }
    return dispose;
  }, [commitUpdateSnapshotMutation, shouldRecord]);
}

const styles = {
  leaderboardRow: css({
    width: 458,
    padding: '8px 28px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 12,
    gap: 18,
    '& img': {
      width: 56,
      height: 56,
      borderRadius: '50%',
    },
  }),
  leaderboardRowCurrentUser: css({
    backgroundColor: '#C0E7B0',
    color: '#00856B',
  }),
  miloCelebratingWrapper: css({
    width: 250,
    height: 250,
    position: 'absolute',
    bottom: 0,
    right: 0,
  }),
} as const;
