/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LeaderboardNotificationScreen_workout = {
    readonly user: {
        readonly profile: {
            readonly __typename: "Student";
            readonly id: string;
            readonly leaderboard?: {
                readonly currentWeek: ReadonlyArray<{
                    readonly studentId: string;
                    readonly rank: number;
                    readonly rankDelta: number | null;
                    readonly name: string;
                    readonly points: number;
                    readonly avatarUrl: string;
                }>;
            } | null | undefined;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    };
    readonly " $refType": "LeaderboardNotificationScreen_workout";
};
export type LeaderboardNotificationScreen_workout$data = LeaderboardNotificationScreen_workout;
export type LeaderboardNotificationScreen_workout$key = {
    readonly " $data"?: LeaderboardNotificationScreen_workout$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LeaderboardNotificationScreen_workout">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "classId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasLeaderboardClass"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaderboardNotificationScreen_workout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "condition": "hasLeaderboardClass",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Leaderboard",
                      "kind": "LinkedField",
                      "name": "leaderboard",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "classId",
                              "variableName": "classId"
                            }
                          ],
                          "concreteType": "CurrentWeekLeaderboardUser",
                          "kind": "LinkedField",
                          "name": "currentWeek",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "studentId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rank",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rankDelta",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "points",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "avatarUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                }
              ],
              "type": "Student",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkoutInterface",
  "abstractKey": "__isWorkoutInterface"
};
(node as any).hash = 'f90b875dbcc2d96ceee7f3bf5f338105';
export default node;
