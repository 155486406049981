import { Suspense, useMemo } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import { InvariantViolation } from 'ms-utils/app-logging';
import type { FixGeneratedKey } from 'ms-utils/relay/relay-compiler-language-typescript-fixes';
import { getStartOfWeek } from 'ms-utils/time';

import type { StudentSkillsMapSubstrandProficiencyIndicatorQuery } from './__generated__/StudentSkillsMapSubstrandProficiencyIndicatorQuery.graphql';
import type {
  StudentSkillsMapSubstrandProficiencyIndicator_student$key,
  StudentSkillsMapSubstrandProficiencyIndicator_student$data,
} from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_student.graphql';
import type {
  StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$key,
  StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$data,
} from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus.graphql';
import type {
  StudentSkillsMapSubstrandProficiencyIndicator_substrand$key,
  StudentSkillsMapSubstrandProficiencyIndicator_substrand$data,
} from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_substrand.graphql';
import SubstrandProficiencyIndicator from '../SubstrandProficiencyIndicator/SubstrandProficiencyIndicator';
import { getGradeSubstrands, getGrowthStartDate } from '../utils';

type Props = {
  size?: number | undefined;
  studentKey: FixGeneratedKey<StudentSkillsMapSubstrandProficiencyIndicator_student$key>;
  studentStrandStatusKey: FixGeneratedKey<StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$key>;
  substrandKey: FixGeneratedKey<StudentSkillsMapSubstrandProficiencyIndicator_substrand$key>;
};

type InnerProps = {
  size: number;
  student: StudentSkillsMapSubstrandProficiencyIndicator_student$data;
  studentStrandStatus: StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$data;
  substrand: StudentSkillsMapSubstrandProficiencyIndicator_substrand$data;
};
const PROFICIENCY_INDICATOR_SIZE = 104;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENT_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_student on LanternStudent {
    selfReportedGrade {
      order
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENTSTRANDSTATUS_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus on StudentStrandStatus {
    status
    lastWorkedOnDiagnosticCheckIn {
      endedAt
      hasFinishedDiagnosticPhase
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_SUBSTRAND_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_substrand on Substrand {
    id
    badgeUrl
    gradeSubstrands {
      id
      gradeStrand {
        grade {
          id
          order
        }
      }
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_QUERY = graphql`
  query StudentSkillsMapSubstrandProficiencyIndicatorQuery(
    $filters: [UserStatusFilterInput!]!
    $timestamp: DateTime
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
          }
          previousStatuses: userStatuses(
            filters: $filters
            timestamp: $timestamp
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
          }
        }
      }
    }
  }
`;

export function StudentSkillsMapSubstrandProficiencyIndicator({
  size = PROFICIENCY_INDICATOR_SIZE,
  studentKey,
  studentStrandStatusKey,
  substrandKey,
}: Props) {
  const student = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENT_FRAGMENT,
    studentKey,
  );
  const studentStrandStatus = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENTSTRANDSTATUS_FRAGMENT,
    studentStrandStatusKey,
  );
  const substrand = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_SUBSTRAND_FRAGMENT,
    substrandKey,
  );

  return (
    <ErrorBoundaryWithRetry
      name="StudentSkillsMapSubstrandProficiencyIndicator"
      fallback={({ retry }) => <Retry retry={retry} />}
    >
      {({ fetchKey }) => (
        <Suspense
          fallback={
            <SubstrandProficiencyIndicator
              currentProficiency={0}
              pastProficiency={0}
              size={size}
              locked={false}
              badgeUrl={substrand.badgeUrl}
            />
          }
        >
          <StudentSkillsMapSubstrandProficiencyIndicatorInner
            size={size}
            student={student}
            studentStrandStatus={studentStrandStatus}
            substrand={substrand}
            fetchKey={fetchKey}
          />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
}

function StudentSkillsMapSubstrandProficiencyIndicatorInner({
  size,
  student,
  studentStrandStatus,
  substrand,
  fetchKey,
}: InnerProps & { fetchKey: number }) {
  const gradeSubstrands = getGradeSubstrands({
    substrand,
    grade: { order: student.selfReportedGrade.order },
  });
  const diagnosticCheckInCompleteTimestamp =
    studentStrandStatus.lastWorkedOnDiagnosticCheckIn?.endedAt;
  const startOfWeek = useMemo(getStartOfWeek, []);
  const previousStatusTimestamp = getGrowthStartDate({
    startOfWeek,
    checkInCompletedAt: diagnosticCheckInCompleteTimestamp,
  });

  const props =
    useLazyLoadQuery<StudentSkillsMapSubstrandProficiencyIndicatorQuery>(
      STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_QUERY,
      {
        filters: [
          {
            curriculumNodeIds: gradeSubstrands.map(
              gradeSubstrand => gradeSubstrand.id,
            ),
          },
        ],
        timestamp: previousStatusTimestamp,
        previewingWithProblemData: false,
        growthPeriod: 120,
      },
      { fetchKey },
    );

  const { viewer } = props.lantern;
  if (viewer == null || viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('The logged in user must be a LanternStudent');
  }
  const currentProficiency = viewer.userStatuses?.[0]?.trueProficiency ?? 0;
  const pastProficiency = viewer.previousStatuses?.[0]?.trueProficiency ?? 0;
  return (
    <SubstrandProficiencyIndicator
      currentProficiency={currentProficiency}
      pastProficiency={pastProficiency}
      locked={false}
      size={size}
      badgeUrl={substrand.badgeUrl}
    />
  );
}
