import { useQuery, graphql } from 'relay-hooks';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

import type { helpersUseIsWithSkillCheckinStepQuery } from './__generated__/helpersUseIsWithSkillCheckinStepQuery.graphql';
import type { SelectedSubtopic } from './components/SubtopicSelector';

type State = {
  taskName: string;
  taskStartDate: Date;
  taskDueDate: Date;
  selectedClasses: readonly ClassSelectionPayload[];
  selectedStudents: readonly StudentSelectionPayload[];
};
export const isTaskCreationFormValid = (state: State): boolean =>
  state.taskName !== '' &&
  state.taskStartDate < state.taskDueDate &&
  (state.selectedClasses.length > 0 || state.selectedStudents.length > 0);
export function useIsWithSkillCheckinStep(
  selectedSubtopics: ReadonlyArray<SelectedSubtopic>,
) {
  const { props } = useQuery<helpersUseIsWithSkillCheckinStepQuery>(
    graphql`
      query helpersUseIsWithSkillCheckinStepQuery(
        $subtopicId: ID!
        $singleSubtopic: Boolean!
      ) {
        subtopic(id: $subtopicId) @include(if: $singleSubtopic) {
          skills {
            id
          }
        }
      }
    `,
    {
      subtopicId: selectedSubtopics[0]?.id ?? '',
      singleSubtopic: selectedSubtopics.length === 1,
    },
  );
  return (
    selectedSubtopics.length === 1 && (props?.subtopic?.skills?.length ?? 0) > 0
  );
}
export type ClassAsIds = {
  classId: string;
  studentIds: string[];
};
export const parseCompleteAndPartialClasses = (
  classes: readonly ClassSelectionPayload[],
  excludedStudents: readonly StudentSelectionPayload[],
) => {
  const classesAsIds: Array<ClassAsIds> = classes.map(c => ({
    classId: c.id,
    studentIds: c.students?.edges.map(({ node }) => node.id) ?? [],
  }));
  const completeClassIds = classesAsIds
    .filter(({ studentIds }) => {
      return !excludedStudents.some(excludedStudent =>
        studentIds.includes(excludedStudent.id),
      );
    })
    .map<string>(({ classId }) => classId);
  const partialClasses = classesAsIds
    .filter(({ studentIds }) => {
      return excludedStudents.some(excludedStudent =>
        studentIds.includes(excludedStudent.id),
      );
    })
    .map<ClassAsIds>(({ classId, studentIds }) => ({
      classId,
      studentIds: studentIds.filter(
        studentId => !excludedStudents.map(s => s.id).includes(studentId),
      ),
    }));
  return { completeClassIds, partialClasses };
};

// From https://github.com/mathspace/mathspace/blob/master/msproblem/content/interaction/tasks/custom_tasks/models.py
// NB: this is an override for custom tasks only, while the base class has a max lenght of 255; But: the safer, the better
export const TASK_TITLE_MAX_LENGTH = 128;

// From https://github.com/mathspace/mathspace/blob/master/msproblem/content/interaction/task_template/models.py
export const TASK_TEMPLATE_TITLE_MAX_LENGTH = 255;
