import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import { capturePageView as capturePosthogPageView } from 'ms-helpers/Posthog';
import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';
import { sendPageView as sendPageViewToGA } from 'ms-utils/ga';

type PublicProps = {
  sendToSnowplow?: boolean;
  sendToPosthog?: boolean;
  sendToGA?: boolean;
};

const activityTrackingOptions = { minimumVisitLength: 10, heartBeat: 10 };

function PageViewTracker(
  props: PublicProps & {
    history?: RouteComponentProps['history'];
  },
) {
  const {
    history,
    sendToGA = false,
    sendToSnowplow = true,
    sendToPosthog = true,
  } = props;

  const { trackPageView, enableActivityTracking } = useSnowplow();

  useEffect(() => {
    if (sendToPosthog) {
      // Posthog automatically sends initial pageview, so no need
      // to do so manually.
    }

    if (sendToSnowplow) {
      enableActivityTracking(activityTrackingOptions);
      trackPageView();
    }

    let cancelListener: (() => void) | null = null;

    if (history && (sendToSnowplow || sendToPosthog || sendToGA)) {
      cancelListener = history.listen(({ pathname }) => {
        if (sendToPosthog) {
          capturePosthogPageView();
        }
        if (sendToSnowplow) {
          enableActivityTracking(activityTrackingOptions);
          trackPageView();
        }
        if (sendToGA) {
          sendPageViewToGA({ page: pathname });
        }
      });
    }

    return () => {
      cancelListener?.();
    };

    // This was ported from a class component, which did not react
    // to changes in props for this effect, so we recapitulate that
    // behaviour here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function PageViewTrackerWithSnowplow(props: PublicProps) {
  return <PageViewTracker {...props} />;
}

export function PageViewTrackerWithRouter(props: PublicProps) {
  const history = useHistory();
  return <PageViewTracker {...props} history={history} />;
}

export default PageViewTrackerWithSnowplow;
