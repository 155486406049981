import type { AppEnv } from 'ms-helpers/AppEnv';

import type { WebToRnMessage, RnToWebMessage } from './webRnInterfaceTypes';
import { isReactNativeAppHost } from '../../ms-helpers/AppEnv/utils';

// Hmmmm, I don't like that we're telling our whole WebJS layer about these
// symbols by doing this extension of the Window interface. We really don't
// want this stuff being referenced elsewhere.
declare global {
  interface Window {
    // This is injected by our WebJS layer to allow RN to call into
    sendMessageToWebApp: (message: RnToWebMessage) => void;
    // This is injected by RN to allow our WebJS layer to send messages
    // to RN
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

type Subscription = (message: RnToWebMessage) => void;

// We will use module state to track the current subscriptions
let subscriptions: Array<Subscription> = [];

const notifySubscribers = (message: RnToWebMessage) => {
  subscriptions.forEach(subscription => {
    subscription(message);
  });
};

// This will be invoked by React Native when it wishes to send a message into the WebView
window.sendMessageToWebApp = (message: RnToWebMessage) => {
  notifySubscribers(message);
};

const subscribeToReactNative = (callback: Subscription, appEnv: AppEnv) => {
  if (!isReactNativeAppHost(appEnv)) {
    throw new Error(
      'subscribeToReactNative must only be invoked in a React Native appEnv',
    );
  }

  subscriptions = [...subscriptions, callback];
  return () => {
    subscriptions = subscriptions.filter(s => s !== callback);
  };
};

type OutboundSubscription = (message: WebToRnMessage) => void;
let outboundSubscriptions: Array<OutboundSubscription> = [];

const notifyOutboundSubscribers = (message: WebToRnMessage) => {
  outboundSubscriptions.forEach(subscription => {
    subscription(message);
  });
};

const subscribeToOutboundMessages = (
  callback: OutboundSubscription,
  appEnv: AppEnv,
) => {
  if (!isReactNativeAppHost(appEnv)) {
    throw new Error(
      'subscribeToOutboundMessages must only be invoked in a React Native appEnv',
    );
  }

  outboundSubscriptions = [...outboundSubscriptions, callback];
  return () => {
    outboundSubscriptions = outboundSubscriptions.filter(s => s !== callback);
  };
};

const sendMessageToReactNative = (message: WebToRnMessage, appEnv: AppEnv) => {
  if (!isReactNativeAppHost(appEnv)) {
    throw new Error(
      'sendMessageToReactNative must only be invoked in a React Native appEnv',
    );
  }

  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    // We must serialize the message object since we are crossing a
    // process boundary from WebJS to RNJS.
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
    // Notify subscribers after attempting to send the message
    notifyOutboundSubscribers(message);
  } else {
    // eslint-disable-next-line no-console
    console.error('React Native environment not configured correctly');
  }
};

export {
  subscribeToReactNative,
  sendMessageToReactNative,
  subscribeToOutboundMessages,
};
