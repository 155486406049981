import { css, StyleSheet } from 'aphrodite';
import { clone } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { color } from 'ms-styles/theme/Bootstrap';
import { toScreen, fromScreen, snapValue } from 'ms-utils/math/cartesian';
import type { Dimensions } from 'ms-utils/math/cartesian';
import { unwrap } from 'ms-utils/typescript-utils';

import ArrowLeft from '../ArrowLeft';
import ArrowRight from '../ArrowRight';
import type { NumberLineSegmentData } from '../index';

type PointKey = 0 | 1 | undefined;

type Props = {
  data: NumberLineSegmentData;
  dimensions: Dimensions;
  dx: number;
  onDragEnd?: ((data: NumberLineSegmentData) => void) | undefined;
  onDragStart?: ((event: React.PointerEvent) => void) | undefined;
  readOnly?: boolean | undefined;
  temp?: boolean | undefined;
};

const Segment = ({
  data,
  dimensions,
  dx,
  onDragEnd: dragEndCallback,
  onDragStart: dragStartCallback,
  readOnly,
  temp,
}: Props) => {
  const [active, setActive] = useState<PointKey>(temp ? 1 : undefined);
  const [hot, setHot] = useState<PointKey>(temp ? 1 : undefined);

  const positionOne = useMemo(
    () =>
      toScreen(unwrap(data[0].position[0]), dimensions) +
      (active === 0 ? dx : 0),
    [active, data, dimensions, dx],
  );

  const positionTwo = useMemo(
    () =>
      toScreen(unwrap(data[1].position[0]), dimensions) +
      (active === 1 ? dx : 0),
    [active, data, dimensions, dx],
  );

  const valueOne = useMemo(() => {
    let value = fromScreen(positionOne, dimensions);
    value = snapValue(value, dimensions);
    return value;
  }, [dimensions, positionOne]);

  const valueTwo = useMemo(() => {
    let value = fromScreen(positionTwo, dimensions);
    value = snapValue(value, dimensions);
    return value;
  }, [dimensions, positionTwo]);

  const onDragEnd = useCallback(() => {
    if (active === undefined) return;
    const clonedData = clone(data);
    const { snapIncrement, scalingFactor } = dimensions;

    const threshold = (snapIncrement * scalingFactor) / 2;
    if (Math.abs(dx) < threshold || dx === 0) {
      // toggleInclusive
      clonedData[active].meta.inclusive = !clonedData[active].meta.inclusive;
    } else if (
      // if someone drags both ends of a segment to -Infinity
      valueOne === -Infinity &&
      valueTwo === -Infinity
    ) {
      // snap one end back to the start value
      clonedData[0].position[0] = dimensions.start;
      clonedData[1].position[0] = valueTwo;
    } else if (
      // if someone drags both ends of a segment to Infinity, snap
      valueOne === Infinity &&
      valueTwo === Infinity
    ) {
      // snap one end back to the end value
      clonedData[0].position[0] = dimensions.end;
      clonedData[1].position[0] = valueTwo;
    } else {
      clonedData[0].position[0] = valueOne;
      clonedData[1].position[0] = valueTwo;
    }

    // keep ends sorted
    const [left, right] = clonedData;
    const nextData: NumberLineSegmentData =
      unwrap(clonedData[0].position[0]) > unwrap(clonedData[1].position[0])
        ? [right, left]
        : [left, right];

    dragEndCallback?.(nextData);
    setActive(undefined);
  }, [active, data, dimensions, dragEndCallback, dx, valueOne, valueTwo]);

  const onDragStart = useCallback(
    (point: PointKey, event: React.PointerEvent) => {
      setActive(point);
      dragStartCallback?.(event);
    },
    [dragStartCallback],
  );

  // We use a pointerup event on the window so that we capture the event
  // even if it is outside our DOM subtree (or event the browser window itself)
  useEffect(() => {
    // We only have active state during drag (ie after pointerDown has fired)
    if (active !== undefined) {
      window.addEventListener('pointerup', onDragEnd, false);
    }
    return () => {
      window.removeEventListener('pointerup', onDragEnd, false);
    };
  }, [active, onDragEnd]);

  const currentColor = useMemo(() => {
    if (temp) {
      return '#AAA';
    }
    if (
      data[0].meta.status === 'correct' ||
      data[1].meta.status === 'correct'
    ) {
      return color.correct;
    }
    if (
      data[0].meta.status === 'incorrect' ||
      data[1].meta.status === 'incorrect'
    ) {
      return color.incorrect;
    }
    if (readOnly) {
      return color.readOnly;
    }
    return color.interactive;
  }, [data, readOnly, temp]);

  const rLarge = 6.5;
  const rSmall = 5;

  const radius = hot === 1 || active === 1 ? rLarge : rSmall;

  const styles = StyleSheet.create({
    clickable: {
      pointerEvents: 'all',
    },

    point: {
      fill: currentColor,
      height: radius * 2,
      stroke: currentColor,
      strokeWidth: 2,
      transform: 'rotate(45deg)',
      width: radius * 2,
    },

    unclickable: {
      pointerEvents: 'none',
    },
  });

  return (
    <g>
      <line
        className={css(styles.unclickable)}
        stroke={currentColor}
        strokeWidth={5}
        x1={positionOne}
        x2={positionTwo}
        y1={0}
        y2={0}
      />
      <g
        className={css(styles.clickable)}
        cursor={readOnly ? undefined : 'pointer'}
        onMouseEnter={readOnly ? undefined : () => setHot(0)}
        onMouseLeave={() => setHot(undefined)}
        onPointerDown={readOnly ? undefined : e => onDragStart(0, e)}
        transform={`translate(${positionOne})`}
      >
        <rect fillOpacity="0" height="40" width="20" x="-10" y="-20" />
        <g className={css(styles.unclickable)}>
          {(() => {
            if (valueOne === -Infinity) {
              return <ArrowLeft color={currentColor} />;
            }
            if (valueOne === Infinity) {
              return <ArrowRight color={currentColor} />;
            }
            const circleRadius = hot === 0 || active === 0 ? rLarge : rSmall;
            return (
              <g>
                <circle
                  cx={0}
                  cy={0}
                  fill={data[0].meta.inclusive ? currentColor : '#fff'}
                  r={circleRadius}
                  stroke={currentColor}
                  strokeWidth={2}
                />
              </g>
            );
          })()}
        </g>
      </g>
      <g
        className={css(styles.clickable)}
        cursor={readOnly ? undefined : 'pointer'}
        onMouseEnter={readOnly ? undefined : () => setHot(1)}
        onMouseLeave={() => setHot(undefined)}
        onPointerDown={readOnly ? undefined : e => onDragStart(1, e)}
        transform={`translate(${positionTwo})`}
      >
        <rect fillOpacity="0" height="40" width="20" x="-10" y="-20" />
        <g className={css(styles.unclickable)}>
          {(() => {
            const isPoint = valueTwo === valueOne;

            if (isPoint) {
              const size = hot === 1 || active === 1 ? rLarge : rSmall;
              return <rect className={css(styles.point)} x={-size} y={-size} />;
            } else if (valueTwo === -Infinity) {
              return <ArrowLeft color={currentColor} />;
            }
            if (valueTwo === Infinity) {
              return <ArrowRight color={currentColor} />;
            }
            const circleRadius = hot === 1 || active === 1 ? rLarge : rSmall;
            return (
              <g>
                <circle
                  cx={0}
                  cy={0}
                  fill={data[1].meta.inclusive ? currentColor : '#FFF'}
                  r={circleRadius}
                  stroke={currentColor}
                  strokeWidth={2}
                />
              </g>
            );
          })()}
        </g>
      </g>
    </g>
  );
};

export default Segment;
