import { css } from '@emotion/css';

import { BACKGROUND_COLOR } from 'ms-pages/Work/layout';
import { fontFamily, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

export const screenCss = css({
  alignItems: 'center',
  backgroundColor: BACKGROUND_COLOR,
  color: colors.grey,
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',
  fontFamily: fontFamily.body,
});

export const screenBodyCss = css({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',
  gap: 24,
  justifyContent: 'center',
  padding: 24,
});

export const screenTitleCss = css({
  fontFamily: fontFamily.heading,
  fontSize: 32,
  fontWeight: 800,
  letterSpacing: '-0.02em',
  lineHeight: 1.1875,
  textAlign: 'center',
});

export const screenLeadTextCss = css({
  fontSize: fontSize.large,
});

export const screenFooterCss = css({
  alignItems: 'center',
  backgroundColor: colors.eggplant,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  justifyContent: 'center',
  minHeight: 160,
  padding: 24,
  width: '100%',
});
