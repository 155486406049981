import React from 'react';

import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { colors } from 'ms-styles/colors';
import { noop } from 'ms-utils/misc';

export function useAccessibilityMode(): [boolean] {
  // useMaybeViewer fails gracefully if a ViewerProvider is not found up in the render tree
  // so, it's safe to use in tests that don't care about accessibility mode
  const { hasEnabledAccessibilityMode } = useMaybeViewer()?.featureFlags ?? {
    hasEnabledAccessibilityMode: false,
  };

  return [hasEnabledAccessibilityMode];
}

export function useToggleAccessibilityMode() {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const { trackStructEvent } = useSnowplow();
  const { updateFeatureFlags } = useMaybeViewer() ?? {
    updateFeatureFlags: noop,
  };

  const toggleAccessibilityMode = React.useCallback(() => {
    if (hasEnabledAccessibilityMode) {
      // track opt-out
      trackStructEvent({
        category: 'accessibility_mode',
        action: 'has_disabled',
      });
    } else {
      // track opt-in
      trackStructEvent({
        category: 'accessibility_mode',
        action: 'has_enabled',
      });
    }
    updateFeatureFlags(
      'hasEnabledAccessibilityMode',
      !hasEnabledAccessibilityMode,
    );
  }, [hasEnabledAccessibilityMode, trackStructEvent, updateFeatureFlags]);

  return toggleAccessibilityMode;
}

// used by the accessibilityModeStyle below
// it's exported for convenience because some components may need to define
// some custom adjustments in non-accessibility mode
export const FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH = 2;
export const FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR = colors.cloudBurst;

// To be spread in a dynamic styled component: see ms-utils/emotion
//
// Example usage:
// const MyStyledComponent = styled({
//   default: {},
//   accessibilityMode: accessibilityModeStyle
// })

export const accessibilityModeStyle = {
  border: `${FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH}px solid transparent`,
  borderRadius: 4,
  ':focus': {
    outline: 'none',
    border: `${FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH}px solid ${FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR}`,
  },
};
