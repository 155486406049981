// DO NOT MODIFY THIS FILE
// Generated by bin/generate_feature_flags_v2.py
import { graphql } from 'react-relay';

graphql`
  fragment featureFlagsV2 on FeatureFlagsV2 @relay(mask: false) {
    canPreviewProblemEventDataInSkills
    catfaEnableSecondarySaveAsTemplate
    checkinsEnableDiscoveryCheckinCapGrades
    devGetProblemEventsBackgroundRequestsPerMinuteLimit
    devSkillsReportEnableTimeFilter
    enableAiOpenPrompt
    enableAiOpenPromptDiscountedPrice
    enableAiPremiumTrial
    gamificationEnableChallengesDaily
    gamificationEnableCustomisation
    gamificationEnableInlineLeaderboardNotification
    gamificationEnableLeaderboardClassSelectionPrompt
    gamificationEnableLeaderboardDock
    gamificationEnableLeaderboardModalV2
    gamificationEnableLeaderboardNotification
    gamificationEnableLeaderboardTab
    gamificationEnableLiveModePromote
    gamificationEnableMonthlyExpedition
    gamificationEnablePointsCheckins
    gamificationEnableQuestionSuccessBannerV2
    gamificationEnableStudentDashboardV2
    gamificationEnableTeacherStickers
    gamificationEnableTugOfWar
    gamificationHighlightStreaksInWorkbookHeader
    globalSearchEnableMVP
    graphqlEnableNodeResolver
    hasPreviewProblemEventDataInSkills
    perfUpdateIndirectMasteryAsync
    preTopicTestCheckInCanAccess
    prototypeEnableWorksheetChat
    reportsEnableRecommendTasks
    showMaintenanceBanner
    skillsActivityReportEnableReport
    skillsAlchemyGradeLevelA13
    skillsAlchemyUseSubtopicGradeFilter
    skillsDisablePENegativePropagation
    skillsEnableCurriculumClassAverage
    skillsReportsEnableStudentSkillsUpdate
    skillsShowGradeLevelOverride
    supportEnableHubspotBubble
    templatesEnableSortLastAssigned
    topicTestsEnableMVP
    workbookEnableDesmosCalculators
    workbookEnableIncorrectStepPrompt
    workbookEnableSpanishSupport
    workbookEnableTTSSupport
  }
`;
export const FEATURE_FLAG_V2_DEFAULTS_FOR_TEST_MOCKS = {
  canPreviewProblemEventDataInSkills: false,
  catfaEnableSecondarySaveAsTemplate: false,
  checkinsEnableDiscoveryCheckinCapGrades: false,
  devGetProblemEventsBackgroundRequestsPerMinuteLimit: 'N1000',
  devSkillsReportEnableTimeFilter: false,
  enableAiOpenPrompt: false,
  enableAiOpenPromptDiscountedPrice: false,
  enableAiPremiumTrial: false,
  gamificationEnableChallengesDaily: false,
  gamificationEnableCustomisation: false,
  gamificationEnableInlineLeaderboardNotification: false,
  gamificationEnableLeaderboardClassSelectionPrompt: false,
  gamificationEnableLeaderboardDock: false,
  gamificationEnableLeaderboardModalV2: false,
  gamificationEnableLeaderboardNotification: false,
  gamificationEnableLeaderboardTab: false,
  gamificationEnableLiveModePromote: false,
  gamificationEnableMonthlyExpedition: false,
  gamificationEnablePointsCheckins: false,
  gamificationEnableQuestionSuccessBannerV2: false,
  gamificationEnableStudentDashboardV2: false,
  gamificationEnableTeacherStickers: false,
  gamificationEnableTugOfWar: false,
  gamificationHighlightStreaksInWorkbookHeader: false,
  globalSearchEnableMVP: false,
  graphqlEnableNodeResolver: false,
  hasPreviewProblemEventDataInSkills: false,
  perfUpdateIndirectMasteryAsync: false,
  preTopicTestCheckInCanAccess: false,
  prototypeEnableWorksheetChat: false,
  reportsEnableRecommendTasks: false,
  showMaintenanceBanner: false,
  skillsActivityReportEnableReport: false,
  skillsAlchemyGradeLevelA13: false,
  skillsAlchemyUseSubtopicGradeFilter: false,
  skillsDisablePENegativePropagation: false,
  skillsEnableCurriculumClassAverage: false,
  skillsReportsEnableStudentSkillsUpdate: false,
  skillsShowGradeLevelOverride: false,
  supportEnableHubspotBubble: false,
  templatesEnableSortLastAssigned: true,
  topicTestsEnableMVP: false,
  workbookEnableDesmosCalculators: false,
  workbookEnableIncorrectStepPrompt: false,
  workbookEnableSpanishSupport: false,
  workbookEnableTTSSupport: false,
} as const;
