import PencilIcon from '../Pencil2';
import type { Props } from '../types';

const PencilReversed = (props: Props) => (
  <div style={{ transform: 'scaleX(-1)' }}>
    <PencilIcon {...props} />
  </div>
);

PencilReversed.displayName = 'PencilReversed';

export default PencilReversed;
