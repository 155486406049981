import { graphql, useQuery } from 'relay-hooks';

import SunflowerStudentNavbarPure from 'ms-components/StudentNavbar/components/SunflowerNavbar';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import { InvariantViolation } from 'ms-utils/app-logging';
import useRerenderAfterHistoryNavigation from 'ms-utils/hooks/useRerenderAfterHistoryNavigation';
import { useRouter } from 'ms-utils/hooks/useRouter';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

import type { StudentNavbarQuery } from './__generated__/StudentNavbarQuery.graphql';

// This seems high for a mobile breakpoint right? The reason we've chosen this
// is simply because we've crammed too many items into the navbar for the
// desktop view, so we've had to increase the breakpoint to account for those
// extra items. So as the screen width gets wider, we'll see the mobile view for
// longer, just until we either a) consolidate some of the items, or b) redesign
// the navbar entirely.
const MOBILE_BREAKPOINT = 896;

type Props = {
  isOnboarding?: boolean | undefined;
};
export default function Navbar({ isOnboarding = false }: Props) {
  const { accountType } = useViewer();
  // Allow external users to access Settings page
  if (accountType === 'EXTERNAL') return null;
  return (
    <PageTimeRecorder componentName="Navbar" pageName="StudentNavbar">
      <StudentNavbarUi isOnboarding={isOnboarding} />
    </PageTimeRecorder>
  );
}
const query = graphql`
  query StudentNavbarQuery {
    viewer {
      avatar
    }
  }
`;
function StudentNavbarUi({ isOnboarding = false }: Props) {
  const {
    location: { pathname },
  } = useRouter();
  const {
    featureFlags: { textbook },
    canHaveAssignedTasks,
  } = useViewer();
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const windowSize = useWindowSize();
  // In the event we are unable to measure the screen, it is safer to
  // default to rendering the mobile view
  const isMobile =
    windowSize.width != null ? windowSize.width < MOBILE_BREAKPOINT : true;
  const fetchKey = useRerenderAfterHistoryNavigation();
  const { props, error } = useQuery<StudentNavbarQuery>(
    query,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' },
  );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        componentName="Navbar"
        pageName="StudentNavbar"
        error={error}
      />
    );
  }
  if (props == null) return null; // silent loading
  if (props.viewer == null) {
    throw new InvariantViolation('Viewer does not exist');
  }
  return (
    <SunflowerStudentNavbarPure
      userAvatar={props.viewer.avatar}
      pathname={pathname}
      hasDashboard
      hasSkills
      hasTasks={canHaveAssignedTasks}
      hasTextbook={textbook}
      hasAccessibilityModeLabel={hasEnabledAccessibilityMode}
      isOnboarding={isOnboarding}
      isMobile={isMobile}
    />
  );
}
