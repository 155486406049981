import { Component, type ReactNode, type ErrorInfo } from 'react';

import { Logger } from 'ms-utils/app-logging';

/**
 * Important: For retry functionality to work, ensure that the inner component
 * receives the fetchKey. This allows the component to re-fetch or re-render
 * based on the updated fetchKey value.
 */
export default class ErrorBoundaryWithRetry extends Component<
  {
    children:
      | ReactNode
      | ((props: { fetchKey: number; retry: () => void }) => ReactNode);
    fallback:
      | ReactNode
      | ((props: { error: Error; retry: () => void }) => ReactNode);
    // This is the name that will be shown in Sentry logs when
    // this error boundary catches exceptions
    name: string;
  },
  { error: Error | null; fetchKey: number }
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override state = { error: null, fetchKey: 0 };

  override componentDidCatch(error: Error, info: ErrorInfo) {
    Logger.error(
      `ErrorBoundary (${this.props.name}) caught error: ${error.message}`,
      {
        extra: {
          componentStack: info.componentStack,
          errorBoundaryName: this.props.name,
        },
      },
    );
  }

  _retry = () => {
    this.setState(prev => ({
      error: null,
      fetchKey: prev.fetchKey + 1,
    }));
  };

  override render() {
    const { children, fallback } = this.props;
    const { error, fetchKey } = this.state;

    if (error) {
      if (typeof fallback === 'function') {
        return fallback({ error, retry: this._retry });
      }
      return fallback;
    }

    if (typeof children === 'function') {
      return children({ fetchKey, retry: this._retry });
    } else {
      return children;
    }
  }
}
