/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdatableByUserFeatureFlagEnum = "hasDismissedOnboardingModalInAdaptiveIntro" | "hasEnabledAccessibilityMode";
export type featureFlagsMutationVariables = {
    featureName: UpdatableByUserFeatureFlagEnum;
    featureValue: boolean;
};
export type featureFlagsMutationResponse = {
    readonly updateFeatureFlag: {
        readonly viewer: {
            readonly featureFlags: {
                readonly canAccessNewDistrictReport: boolean;
                readonly canAccessNewDistrictReportImpact: boolean;
                readonly canAccessNewDistrictReportSchoolLevel: boolean;
                readonly canAccessNewDistrictReportTemplates: boolean;
                readonly canvasEnableGradePassback: boolean;
                readonly checkinsEnableGrowthPeriodFilter: boolean;
                readonly enableAiSuggestQuestions: boolean;
                readonly hasEnabledAccessibilityMode: boolean;
                readonly hasStudentAppSpa: boolean;
                readonly hasUpdatedCorrectStepIcons: boolean;
                readonly insightsEnableLearningHighlights: boolean;
                readonly printEnablePageBreaksAfterPTs: boolean;
                readonly showWorkbookOnboarding: boolean;
                readonly adminReports: boolean;
                readonly advancedReports: boolean;
                readonly allowMultipleTeachersInClass: boolean;
                readonly assignTasksToIndividuals: boolean;
                readonly assignableTasks: boolean;
                readonly bulkAssignTasks: boolean;
                readonly classicUi: boolean;
                readonly demoCheckin: boolean;
                readonly diagnostics: boolean;
                readonly engageLite: boolean;
                readonly engageTasks: boolean;
                readonly primaryTeacherNotes: boolean;
                readonly printableWorksheets: boolean;
                readonly schoolReports: boolean;
                readonly teacherReporting: boolean;
                readonly templates: boolean;
                readonly textbook: boolean;
                readonly textbookOverviews: boolean;
                readonly topicAssessment: boolean;
                readonly unlimitedClasses: boolean;
                readonly unlimitedOpenPrompt: boolean;
                readonly worksheetAnswerKeys: boolean;
                readonly worksheets: boolean;
                readonly canEditLanternQuestions: boolean;
            };
        } | null;
    };
};
export type featureFlagsMutation = {
    readonly response: featureFlagsMutationResponse;
    readonly variables: featureFlagsMutationVariables;
};



/*
mutation featureFlagsMutation(
  $featureName: UpdatableByUserFeatureFlagEnum!
  $featureValue: Boolean!
) {
  updateFeatureFlag: updateTypedFeatureFlag(feature: $featureName, value: $featureValue) {
    viewer {
      featureFlags: typedFeatureFlags {
        canAccessNewDistrictReport
        canAccessNewDistrictReportImpact
        canAccessNewDistrictReportSchoolLevel
        canAccessNewDistrictReportTemplates
        canvasEnableGradePassback
        checkinsEnableGrowthPeriodFilter
        enableAiSuggestQuestions
        hasEnabledAccessibilityMode
        hasStudentAppSpa
        hasUpdatedCorrectStepIcons
        insightsEnableLearningHighlights
        printEnablePageBreaksAfterPTs
        showWorkbookOnboarding
        adminReports
        advancedReports
        allowMultipleTeachersInClass
        assignTasksToIndividuals
        assignableTasks
        bulkAssignTasks
        classicUi
        demoCheckin
        diagnostics
        engageLite
        engageTasks
        primaryTeacherNotes
        printableWorksheets
        schoolReports
        teacherReporting
        templates
        textbook
        textbookOverviews
        topicAssessment
        unlimitedClasses
        unlimitedOpenPrompt
        worksheetAnswerKeys
        worksheets
        canEditLanternQuestions
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "featureName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "featureValue"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "feature",
    "variableName": "featureName"
  },
  {
    "kind": "Variable",
    "name": "value",
    "variableName": "featureValue"
  }
],
v2 = {
  "alias": "featureFlags",
  "args": null,
  "concreteType": "TypedFeatureFlag",
  "kind": "LinkedField",
  "name": "typedFeatureFlags",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportImpact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportSchoolLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportTemplates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canvasEnableGradePassback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkinsEnableGrowthPeriodFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiSuggestQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEnabledAccessibilityMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStudentAppSpa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUpdatedCorrectStepIcons",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insightsEnableLearningHighlights",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printEnablePageBreaksAfterPTs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showWorkbookOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advancedReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowMultipleTeachersInClass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignTasksToIndividuals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignableTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkAssignTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classicUi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demoCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnostics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageLite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryTeacherNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printableWorksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teacherReporting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookOverviews",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicAssessment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedClasses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetAnswerKeys",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditLanternQuestions",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "featureFlagsMutation",
    "selections": [
      {
        "alias": "updateFeatureFlag",
        "args": (v1/*: any*/),
        "concreteType": "UpdateTypedFeatureFlagResult",
        "kind": "LinkedField",
        "name": "updateTypedFeatureFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "featureFlagsMutation",
    "selections": [
      {
        "alias": "updateFeatureFlag",
        "args": (v1/*: any*/),
        "concreteType": "UpdateTypedFeatureFlagResult",
        "kind": "LinkedField",
        "name": "updateTypedFeatureFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef5916aba5786036b712983d7f1668af",
    "id": null,
    "metadata": {},
    "name": "featureFlagsMutation",
    "operationKind": "mutation",
    "text": "mutation featureFlagsMutation(\n  $featureName: UpdatableByUserFeatureFlagEnum!\n  $featureValue: Boolean!\n) {\n  updateFeatureFlag: updateTypedFeatureFlag(feature: $featureName, value: $featureValue) {\n    viewer {\n      featureFlags: typedFeatureFlags {\n        canAccessNewDistrictReport\n        canAccessNewDistrictReportImpact\n        canAccessNewDistrictReportSchoolLevel\n        canAccessNewDistrictReportTemplates\n        canvasEnableGradePassback\n        checkinsEnableGrowthPeriodFilter\n        enableAiSuggestQuestions\n        hasEnabledAccessibilityMode\n        hasStudentAppSpa\n        hasUpdatedCorrectStepIcons\n        insightsEnableLearningHighlights\n        printEnablePageBreaksAfterPTs\n        showWorkbookOnboarding\n        adminReports\n        advancedReports\n        allowMultipleTeachersInClass\n        assignTasksToIndividuals\n        assignableTasks\n        bulkAssignTasks\n        classicUi\n        demoCheckin\n        diagnostics\n        engageLite\n        engageTasks\n        primaryTeacherNotes\n        printableWorksheets\n        schoolReports\n        teacherReporting\n        templates\n        textbook\n        textbookOverviews\n        topicAssessment\n        unlimitedClasses\n        unlimitedOpenPrompt\n        worksheetAnswerKeys\n        worksheets\n        canEditLanternQuestions\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '31e828c9d007c67fae5a6f49ead4662e';
export default node;
