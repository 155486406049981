import React, { useEffect, useRef, useState } from 'react';
import type { ComponentType, ReactElement, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import uuidv4 from 'uuid-browser';

import {
  createPageTimingEvent,
  createPageTimingErrorEvent,
} from 'ms-helpers/PageTimeTracker/utils';
import { useSnowplow } from 'ms-helpers/Snowplow/useSnowplow';

// Initial time zero
// We declare at module import time as this will capture time before React begins rendering
let timeZero: number = performance.now();

// Registry of already sent events.
// This is used so that if a relay container is called more than once,
// we only capture the first occurrence
// This gets reset when a history event occurs
class Registry {
  registryStates = Object.freeze({ initialised: 1, finalised: 2 });
  registeredEvents: { [key: string]: number };
  instanceId: string;
  constructor() {
    this.instanceId = uuidv4();
    this.registeredEvents = {};
  }

  static createKey(pageName: string, componentName: string) {
    return [pageName, componentName].join(',');
  }

  initialisePage(pageName: string, componentName: string) {
    const key = Registry.createKey(pageName, componentName);
    this.registeredEvents[key] =
      this.registeredEvents[key] || this.registryStates.initialised;
  }

  finialisePage(pageName: string, componentName: string) {
    const key = Registry.createKey(pageName, componentName);
    this.registeredEvents[key] = this.registryStates.finalised;
  }

  canFinalise(pageName: string, componentName: string): boolean {
    return (
      this.registeredEvents[Registry.createKey(pageName, componentName)] !==
      this.registryStates.finalised
    );
  }
}
let registry: Registry = new Registry();

function getDisplayName(WrappedComponent: ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// --------------------------------------------------
// HOC to register history event listeners.
// Mainly used with React Router, but can be used with any component to alleviate the caller
// from having to write branching code

// Apologies, this provides correct external facing typing
// but does internal type erasure
const identity = <T extends ComponentType>(t: T) => t;

function withPageTimer<Config extends Record<string, unknown>>(
  WrappedComponent: ComponentType<Config>,
  hasRouter: boolean = false,
): ComponentType<Config> {
  // These have incompatible call signatures, so we have to cast the identity
  // function to withRouter's type (this is unsound, as it allows us to declare
  // incorrect props in the function passed to withRouting — for example saying
  // that a history prop always exists, which clearly isn't the case when
  // withRouting is bound to the identity function).
  // TODO redo the implementation so it's type sound
  const withRouting = hasRouter ? withRouter : (identity as typeof withRouter);

  const PageTimer = withRouting(
    (props: { history?: RouteComponentProps['history'] }) => {
      const cancelHistoryListener = useRef<(() => void) | null>(null);
      const pathName = props.history?.location.pathname;

      useEffect(() => {
        if (props.history) {
          cancelHistoryListener.current = props.history.listen(() => {
            timeZero = performance.now();
            registry = new Registry();
          });
        }

        return () => {
          if (cancelHistoryListener && cancelHistoryListener.current != null) {
            cancelHistoryListener.current();
            cancelHistoryListener.current = null;
          }
        };
      }, [pathName, props.history]);

      return null;
    },
  );

  const component = (props: Config) => (
    <WrappedComponent {...props}>
      <PageTimer />
      {props.children}
    </WrappedComponent>
  );

  component.displayName = `WithPageTimer(${getDisplayName(WrappedComponent)})`;

  return component;
}

// --------------------------------------------------
// Hook to record a Snowplow page-timing event
export function usePageTimeRecorder(
  currentPageName: string,
  callerComponentName: string,
  isError: boolean = false,
) {
  registry.initialisePage(currentPageName, callerComponentName);
  const [timing, setTiming] = useState(performance.now());
  const { trackStructEvent } = useSnowplow();

  // Use a ref and the registry so that we don't get rogue timing calls due to multiple renders
  const hasFired = React.useRef(false);

  if (hasFired.current === false) {
    hasFired.current = true;
    if (registry.canFinalise(currentPageName, callerComponentName)) {
      registry.finialisePage(currentPageName, callerComponentName);
      const now = performance.now();
      setTiming(now - timeZero);

      /* Record Snowplow event with the following parameters
         category: page_timing
         action: The component being timed
         label: The name of the page being timed
         property: a UUID of the page for this time recording
         value: time in ms
       */
      const event = {
        action: callerComponentName,
        label: currentPageName,
        property: registry.instanceId,
        value: Math.round(now - timeZero),
      };

      trackStructEvent(
        isError
          ? createPageTimingErrorEvent(event)
          : createPageTimingEvent(event),
      );

      if (process.env.NODE_ENV !== 'production') {
        console.debug(event); // eslint-disable-line no-console
      }
    }
  }

  useEffect(
    () => () => {
      timeZero = performance.now();
    },
    [],
  );

  return process.env.SHOW_TIMING_INDICATORS
    ? timing && (
        <div>{`Took ${timing} to render ${currentPageName} at ${window.location.pathname}`}</div>
      )
    : null;
}

type PageTimeRecorderProps = {
  pageName: string;
  componentName: string;
  children: ReactNode;
};

function record(props: PageTimeRecorderProps, isError = false): ReactElement {
  const { pageName, componentName } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pageTime = usePageTimeRecorder(pageName, componentName, isError);
  return (
    <>
      {props.children}
      {pageTime}
    </>
  );
}

export function PageTimeRecorder(props: PageTimeRecorderProps): ReactElement {
  return record(props, false);
}

export function PageTimeErrorRecorder(
  props: PageTimeRecorderProps,
): ReactElement {
  return record(props, true);
}

export default withPageTimer;
export { PageTimeErrorThrower, PageTimeErrorBoundary } from './errorhandlers';
