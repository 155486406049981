/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LeaderboardNotificationScreen_leaderboardEntry = {
    readonly studentId: string;
    readonly rank: number;
    readonly rankDelta: number | null;
    readonly name: string;
    readonly points: number;
    readonly avatarUrl: string;
};
export type LeaderboardNotificationScreen_leaderboardEntry$data = LeaderboardNotificationScreen_leaderboardEntry;
export type LeaderboardNotificationScreen_leaderboardEntry$key = {
    readonly " $data"?: LeaderboardNotificationScreen_leaderboardEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LeaderboardNotificationScreen_leaderboardEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "LeaderboardNotificationScreen_leaderboardEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rankDelta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    }
  ],
  "type": "CurrentWeekLeaderboardUser",
  "abstractKey": null
};
(node as any).hash = '358b93246031488c3f84da0333661716';
export default node;
